<template>
  <MainWrapper :dark-mode="darkMode" class="h-full">
    <Layout class="layout h-full" :class="rtl ? 'ant-layout-rtl' : ''">
      <Header
        :style="{
          [!rtl ? 'left' : 'right']: 0,
        }"
        class="h-[60px] w-full fixed top-0 flex px-0 border-b-1 border-solid border-ems-gray700"
      >
        <div
          class="flex justify-between items-center h-full bg-[#2B2A3A] px-[15px] border-r-1 border-solid border-ems-gray700 border-r-[#444459]"
          :class="collapsed ? 'w-[80px]' : 'w-[317px]'"
        >
          <router-link
            :class="
              topMenu && innerWidth > 991
                ? 'striking-logo top-menu'
                : 'striking-logo'
            "
            to="/"
            @click="backHome"
            v-if="!collapsed"
          >
            <div class="">
              <img
              src="../static/img/logo_sidebar.png"
              alt="logo"
              class="h-[38px]"
            />
            </div>
          </router-link>
          <button
            v-if="!topMenu || innerWidth <= 991"
            @click="toggleCollapsed"
            :type="'white'"
            class="w-fit h-fit"
          >
            <img
              :src="
                require(`../static/img/icon/${
                  collapsed ? 'nav-expand.png' : 'nav-collapse.png'
                }`)
              "
              alt="menu"
              height="24"
              width="24"
              class="!h-[24px] !w-[24px]"
            />
          </button>
        </div>
        <div
          class="flex-1 h-full header-custom flex justify-end items-center pr-[24px] relative"
        >
          <HeaderCustom :title-name="titleHeader" />

          <a-col :lg="6" :md="10" :sm="0" :xs="0">
            <div class="flex items-center justify-end">
              <div class="nav-author">
                <cds-dropdown :action="['click']" class="wide-dropdwon">
                  <template #overlay>
                    <div
                      v-for="item in languages"
                      :key="item.id"
                      @click="handleSetLocale(item.value)"
                      class="py-1 hover:bg-gray-300 mt-1 first:mt-0 flex items-center gap-x-1 px-2 cursor-pointer"
                    >
                      <img
                        :src="require(`../static/img/icon/${item.value}.svg`)"
                        :alt="item.value"
                        height="24"
                        width="24"
                      />
                      <span
                        :class="`${locale === item.value ? 'font-bold' : ''}`"
                        >{{ item.name }}
                      </span>
                    </div>
                  </template>
                  <img
                    :src="require(`../static/img/icon/${locale}.svg`)"
                    :alt="locale"
                    class="cursor-pointer"
                    height="24"
                    width="24"
                  />
                </cds-dropdown>
              </div>
              <AuthInfo />
            </div>
          </a-col>
        </div>
      </Header>
      <div class="header-more">
        <a-row>
          <a-col :md="0" :sm="24" :xs="24">
            <div class="small-screen-headerRight"></div>
          </a-col>
        </a-row>
      </div>

      <Layout :class="rtl ? 'ant-layout-rtl' : ''" class="h-full">
        <template v-if="!topMenu || innerWidth <= 991">
          <Sider
            :width="317"
            :style="{
              margin: '60px 0 0 0',
              overflowY: 'auto',
              height: 'calc(100vh - 60px)',
              position: 'fixed',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
            }"
            :collapsed="collapsed"
            :theme="!darkMode ? 'light' : 'dark'"
            class="flex flex-col justify-between h-full"
            style="border-right: 1px solid #444459"
          >
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <AsideItems
                :toggle-collapsed="toggleCollapsedMobile"
                :top-menu="topMenu"
                :dark-mode="darkMode"
                :events="onEventChange"
                :class="rtl ? 'ant-menu-rtl' : ''"
              />
            </perfect-scrollbar>
            <div
              class="h-[40px] px-[15px] flex justify-start items-center"
              style="border-top: 1px solid #2B2A3A"
            >
              <span
                v-if="!collapsed"
                class="text-xs text-ems-main2"
                style="letter-spacing: 1.2px"
                >version 1.0</span
              >
            </div>
          </Sider>
        </template>
        <Layout
          class="atbd-main-layout h-[calc(100vh-60px)]"
          :class="rtl ? 'ant-layout-rtl' : ''"
        >
          <Content>
            <Suspense>
              <template #default>
                <router-view></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
    <popupCall v-if="isShowMiniCall"/>
  </MainWrapper>
</template>
<script>
import {Layout} from 'ant-design-vue';
import {MainWrapper} from './style';
import AuthInfo from '../components/utilities/auth-info/info';
import AsideItems from './Aside';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import popupCall from '../view/strategic-coordination/components/walkie-talkie/tab/components/popup-call.vue';
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';
import HeaderCustom from '@/components/header-custom/index.vue';

const { Header, Sider, Content } = Layout;

export default defineComponent({
  name: 'WithAdminLayout',
  components: {
    MainWrapper,
    Header,
    Layout,
    Sider,
    Content,
    AuthInfo,
    AsideItems,
    PerfectScrollbar,
    HeaderCustom,
    popupCall
  },
  setup() {
    const { locale } = useI18n();
    const languages = reactive([
      {
        id: 1,
        name: 'English',
        value: 'en',
      },
      {
        id: 2,
        name: 'Tiếng Việt',
        value: 'vi',
      },
      {
        id: 3,
        name: 'русский язык',
        value: 'ru',
      },
    ]);
    const router = useRouter();
    const collapsed = ref(true);
    const hide = ref(true);
    const searchHide = ref(true);
    const customizerAction = ref(false);
    const activeSearch = ref(false);
    const { dispatch, state } = useStore();
    const isShowMiniCall = computed(() => state.coordinator.isShowMiniCall);
    onMounted(() => {
      // dispatch('getNotifications');
    });
    const rtl = computed(() => state.themeLayout.rtlData);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const titleHeader = computed(() => state.themeLayout.titleHeader);

    // collapsed.value = window.innerWidth <= 1200 && true;

    const toggleCollapsed = (e) => {
      e.preventDefault();
      collapsed.value = !collapsed.value;
      dispatch('setCollapsed', collapsed.value);
    };
    const handleSearchHide = (search) => {
      searchHide.value = !search;
      hide.value = true;
    };
    const onShowHide = (h) => {
      hide.value = !h;
      searchHide.value = true;
    };

    const toggleSearch = () => {
      activeSearch.value = !activeSearch.value;
    };

    const toggleCollapsedMobile = () => {
      if (innerWidth <= 990) {
        collapsed.value = !collapsed.value;
      }
    };

    window.addEventListener('load', () => {
      const html = document.querySelector('html');
      state.themeLayout.rtlData === true
        ? html.setAttribute('dir', 'rtl')
        : html.setAttribute('dir', 'ltr');
    });

    const modeChangeDark = () => {
      dispatch('changeLayoutMode', true);
    };

    const modeChangeLight = () => {
      dispatch('changeLayoutMode', false);
    };

    const modeChangeSideNav = () => {
      dispatch('changeMenuMode', false);
    };

    const backHome = () => {
      dispatch('setTitleHeader', '');
    };

    const onEventChange = {
      modeChangeDark,
      modeChangeLight,
      modeChangeSideNav,
    };

    const handleSetLocale = (payload) => {
      locale.value = payload;
      localStorage.setItem('locale', payload);
      router.go();
    };

    const selectedLocale = localStorage.getItem('locale');
    if (selectedLocale) {
      locale.value = selectedLocale;
    } else {
      locale.value = 'en';
      localStorage.setItem('locale', 'en');
    }

    return {
      languages,
      handleSetLocale,
      locale,
      toggleCollapsed,
      handleSearchHide,
      toggleCollapsedMobile,
      onShowHide,
      collapsed,
      hide,
      searchHide,
      toggleSearch,
      customizerAction,
      activeSearch,
      innerWidth: window.innerWidth,
      rtl,
      darkMode,
      topMenu,
      onEventChange,
      titleHeader,
      backHome,
      isShowMiniCall,
      ...onEventChange,
    };
  },
});
</script>
<style lang="scss" scoped>

.ps {
  height: calc(100vh - 100px);
}
.header-custom {
  background-image: url('../static/img/bg-header.png');
  background-size: auto;
  background-repeat: round;
}
.ant-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: start;
}

</style>
