<template>
  <teleport to="body">
    <keep-alive>
      <div v-if="showPopup" ref="popupRef" class="floating-call select-none">
        <TabWalkieTalkie @mousedown="startDrag" />
      </div>
    </keep-alive>
  </teleport>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import TabWalkieTalkie from '../tab-walkie-talkie.vue';
const showPopup = ref(true);
const videoRef = ref(null);
const popupRef = ref(null);
let offsetX = 0,
  offsetY = 0,
  isDragging = false;

onMounted(() => {
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then((stream) => {
      videoRef.value.srcObject = stream;
    })
    .catch((error) => {});
});
const startDrag = (event) => {
  isDragging = true;
  offsetX = event.clientX - popupRef.value.offsetLeft;
  offsetY = event.clientY - popupRef.value.offsetTop;
  document.addEventListener('mousemove', onDrag);
  document.addEventListener('mouseup', stopDrag);
};

const onDrag = (event) => {
  if (isDragging) {
    popupRef.value.style.left = `${event.clientX - offsetX}px`;
    popupRef.value.style.top = `${event.clientY - offsetY}px`;
  }
};

const stopDrag = () => {
  isDragging = false;
  document.removeEventListener('mousemove', onDrag);
  document.removeEventListener('mouseup', stopDrag);
};
</script>

<style scoped>

.floating-call {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 300px;
  height: max-content;
  background: #3a5ef6;
  border-radius: 16px;
  overflow: hidden;
  cursor: grab;
  z-index: 9998;
}
video {
  width: 100%;
  height: 75%;
}
.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  cursor: pointer;
}

</style>
